<template>
  <div>
    <div class="section-header">
      <h3>Financials</h3>
      <StandaloneScoreInput :issuer="issuer" dbkey="financials.score"></StandaloneScoreInput>

      <div v-if="!$vuetify.display.xs" class="section-header-right ml-sm-0 ml-md-auto">
        <div>
          <v-tooltip location="bottom" activator="parent">
            <div>Click to upload excel file to update. Worksheet {{ excelSheetName }} is required</div>
          </v-tooltip>
          <v-file-input
            class="file-upload-control"
            density="default"
            prepend-icon="mdi-cloud-upload-outline"
            :disabled="uploadErrorMessage !== ''"
            accept=".xls,.xlsx,application/msexcel,application/vnd.ms-excel"
            @update:model-value="upload"
          ></v-file-input>
        </div>

        <v-btn-toggle v-model="viewType" mandatory rounded density="compact">
          <v-btn size="x-small">Data</v-btn>
          <v-btn size="x-small">Comments</v-btn>
        </v-btn-toggle>
      </div>
    </div>

    <div
      v-if="viewType === 0"
      :class="{
        'financial-table-wrap': financialsData.length > 0,
        'content-card': financialsData.length === 0
      }"
      :style="{
        height: $vuetify.display.xs ? 'auto' : '360px'
      }"
    >
      <v-alert
        v-if="uploadErrorMessage"
        class="mb-0"
        density="compact"
        closable
        text
        type="error"
        style="font-size: 12px"
        @update:model-value="uploadErrorMessage = ''"
      >
        {{ uploadErrorMessage }}
      </v-alert>

      <v-alert
        v-if="financialsData.length === 0"
        text
        density="compact"
        color="info"
        type="info"
        style="font-size: 12px"
      >
        Please click
        <v-icon>mdi-cloud-upload-outline</v-icon>
        on the top to upload data for current issuer
      </v-alert>

      <table
        v-else
        style="width: 100%; display: table; border: none !important; border-collapse: collapse"
        class="v-table v-data-table__dense financial-table number-column-preset inset-table"
      >
        <thead>
          <tr>
            <th v-for="(header, index) in financialsData[0]" :key="index">
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in financialsData.slice(1)" :key="index">
            <td v-for="(cell, cellIndex) in row" :key="cellIndex">{{ cell }}</td>
            <td v-for="i in financialsData[0].length - row.length" :key="row.length + i"></td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td :colspan="financialsData[0].length">
              <span style="font-style: italic; font-size: 10px; font-weight: 400">
                Currency:{{ meta.currency }}; Units: {{ meta.units }} when applicable
              </span>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>

    <div v-if="expandedView || viewType === 1" class="content-card pr-1">
      <h4 v-if="expandedView" class="section-title-incard">
        <v-icon size="small">mdi-file-document-outline</v-icon>
        Comments
      </h4>

      <DocContentEditor
        :style="{ height: expandedView ? 'auto' : '344px' }"
        :show-byline="false"
        :resource-path="`/api/corp_crm_models/${issuer}`"
        remote-key="financials.comments"
      />
    </div>
  </div>
</template>

<script>
import XLSX from "xlsx"
import { pick } from "lodash-es"
import DocContentEditor from "@/components/common/DocContentEditor.vue"
import StandaloneScoreInput from "@/components/common/StandaloneScoreInput.vue"

export default {
  components: { DocContentEditor, StandaloneScoreInput },

  inject: ["apiRepo"],

  props: {
    issuer: { type: String, required: true },
    expandedView: { type: Boolean, default: false }
  },

  data() {
    return {
      viewType: 0,
      excelSheetName: "Upload Financials",
      uploadErrorMessage: "",
      meta: {},
      financialsData: []
    }
  },

  async mounted() {
    const api = await this.apiRepo.makeServiceAPIClient()
    const { data, status } = await api.get(`/api/corp_crm_models/${this.issuer}?path=financials.details`)
    if (status === 200 && data) {
      this.meta = data.meta
      this.financialsData = data.financialsData
    }
  },

  methods: {
    upload(files) {
      if (files.length === 0) return

      const file = files[0]
      const vm = this
      const reader = new FileReader()
      reader.onload = async function (e) {
        const data = e.target.result
        const workbook = XLSX.read(data, { type: "array" })
        const sheet = workbook.Sheets[vm.excelSheetName]
        if (sheet) {
          const meta = XLSX.utils.sheet_to_json(sheet, { header: 1, raw: true, range: "A1:B4" })
          vm.meta = {
            issuer: meta[0][1],
            currency: meta[1][1],
            units: meta[2][1],
            financials: meta[3][1]
          }
          if (String(vm.meta.issuer) !== vm.issuer) {
            vm.uploadErrorMessage = "Incorrect model uploaded for current issuer."
            return
          }
          vm.uploadErrorMessage = ""

          vm.financialsData = XLSX.utils.sheet_to_json(sheet, {
            header: 1,
            raw: false,
            range: "A5:F30",
            blankrows: false
          })
          const payload = pick(vm, ["meta", "financialsData"])
          const api = await vm.apiRepo.makeServiceAPIClient()
          const { status } = await api.patch(`/api/corp_crm_models/${vm.issuer}`, {
            path: "financials.details",
            value: payload,
            update_crm_date: true
          })
          if (status !== 200) {
            vm.uploadErrorMessage = "failed to upload financial data"
          }
        } else {
          vm.uploadErrorMessage = `Invalid sheet name. Please ensure sheet name ${vm.excelSheetName} exists in the uploaded file.`
          return
        }
      }
      reader.readAsArrayBuffer(file)
    }
  }
}
</script>

<style lang="scss">
.financial-table-wrap {
  overflow: auto;
  border-radius: 4px;

  .v-theme--light & {
    border: 1px solid #ccc;
  }

  .v-theme--dark & {
    border: 1px solid #3c3c3c;
  }
}
.financial-table.number-column-preset {
  tr > th,
  tr > td {
    text-align: right;

    &:first-child {
      text-align: left;
    }
  }
}
</style>
